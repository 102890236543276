import React from "react"
import Reviews from "../../components/Reviews/Reviews"
import Slider from "react-slick"

import ReviewCard from "../../components/ReviewCard/ReviewCard"
import Stars from "../../components/Stars/Stars"

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,

  autoplay: false,
}

const mocked = [
  {
    key: "r3q1",
    title: "Dott. Lucia Maria",
    subTitle: "Mastoplastica additiva",
    date: "17 novembre 2020",
    author: "Mario Rossi",
    body:
      " Lorem ipsum dolor sit amet, consectetur odisciping elit. Lorem ipsum dolor sit amet, consectetur odisciping elit Lorem ipsum dolor sit amet, consectetur odisciping elit Lorem ipsum dolor sit amet, consectetur odisciping elit Lorem ipsum dolor sit amet,",
  },
  {
    key: "r3q1",
    title: "Dott. Lucia Maria",
    subTitle: "Mastoplastica additiva",
    date: "17 novembre 2020",
    author: "Mario Rossi",
    body:
      " Lorem ipsum dolor sit amet, consectetur odisciping elit. Lorem ipsum dolor sit amet, consectetur odisciping elit Lorem ipsum dolor sit amet, consectetur odisciping elit Lorem ipsum dolor sit amet, consectetur odisciping elit Lorem ipsum dolor sit amet,",
  },
  {
    key: "r3q1",
    title: "Dott. Lucia Maria",
    subTitle: "Mastoplastica additiva",
    date: "17 novembre 2020",
    author: "Mario Rossi",
    body:
      " Lorem ipsum dolor sit amet, consectetur odisciping elit. Lorem ipsum dolor sit amet, consectetur odisciping elit Lorem ipsum dolor sit amet, consectetur odisciping elit Lorem ipsum dolor sit amet, consectetur odisciping elit Lorem ipsum dolor sit amet,",
  },
]

export default function ReviewsMobileContainer() {
  return (
    <Reviews.Mobile>
      <Reviews.Wrapper>
        <Reviews.Title>Le ultime recensioni dei pazienti</Reviews.Title>
        <Reviews.SubTitle>
          Leggi le ultime recensioni sui nostri specialisti di <br />
          Chirurgia e Medicina estetica
        </Reviews.SubTitle>
        <Reviews.SlidesContainer>
          <Slider {...settings}>
            {mocked.map(r => (
              <ReviewCard>
                <ReviewCard.Row>
                  <ReviewCard.Title>{r.title}</ReviewCard.Title>
                  <ReviewCard.Date>{r.date}</ReviewCard.Date>
                </ReviewCard.Row>
                <ReviewCard.Row>
                  <ReviewCard.SubTitle>{r.subTitle}</ReviewCard.SubTitle>
                  <Stars style={{ marginBottom: 5 }} fontSize={11} count={3} />
                </ReviewCard.Row>
                <ReviewCard.Body>{r.body}</ReviewCard.Body>
                <ReviewCard.Author>{r.author}</ReviewCard.Author>
              </ReviewCard>
            ))}
          </Slider>
        </Reviews.SlidesContainer>
      </Reviews.Wrapper>
    </Reviews.Mobile>
  )
}
